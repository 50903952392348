<!--
 * @Author: ce
 * @Date: 2022-09-08 13:46:24
 * @LastEditors: ce
 * @LastEditTime: 2022-09-09 09:05:20
 * @FilePath: /hitarget-offical/src/views/product/components/project2.vue
-->
<template>
  <div class="project mt-48">
    <span class="title1 main-title">{{ $t('product.title3') }}</span>
    <div class="flex flex-wrap mt-32">
      <div class="img " :style="{ backgroundImage: `url(${ infos[locale].img })` }"></div>
      <div class="data flex content-center items-center w-260 h-280">
        <span>7000{{ infos[locale].img.data }} <i class="unit">{{ infos[locale].text }}</i></span>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue'
  import { useMainStore } from "@/store/modules/main.js";
  import store from '@/store/index'
  import { storeToRefs } from 'pinia';
  const mainStore = useMainStore(store)
  const { locale } = storeToRefs(mainStore)
  const infos = ref({
    zh: {
      img: require('@/assets/imgs/project-3.png'),
      data: '万',
      text: '㎡以上不动产测绘'
    },
    en: {
      img: require('@/assets/imgs/project-4.png'),
      data: 'W',
      text: 'Real estate mapping above ㎡'
    }
  })
</script>

<style lang="scss" scoped>
  .title1 {
    font-family: 'HarmonyOS Sans';
    font-weight: 700;
    font-size: 33px;
    line-height: 39px;
    color: #202632;
  }
  .project {
    .img {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      height: 280px;
      background-size: 710px 240px;
      background-position: center center;
      background-repeat: no-repeat;
      flex: 1;
      margin-right: 20px;
    }
    .data {
      background: #528FFF;
      border-radius: 4px;
      padding: 0 43px ;
      box-sizing: border-box;
      span {
        font-family: 'DIN';
        font-weight: 700;
        font-size: 40px;
        line-height: 52px;
        text-align: center;
        color: #FFFFFF;
        .unit {
          font-family: 'Source Han Sans CN';
          font-size: 20px;
          line-height: 30px;
          text-align: center;
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }
</style>