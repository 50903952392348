<!--
 * @Author: ce
 * @Date: 2022-09-06 17:10:51
 * @LastEditors: ce
 * @LastEditTime: 2022-09-09 17:15:40
 * @FilePath: /hitarget-offical/src/views/contact/components/methods.vue
-->
<template>
  <div
    id="methods"
    class="methods mt-60 flex flex-vertical content-center items-center"
  >
    <div class="methods-inner">
      <span class="title1 main-title">{{ $t("contact.title2") }}</span>
      <div class="flex mt-32">
        <div class="lists flex flex-vertical">
          <div
            class="item"
            v-for="(item, index) in infos[locale]"
            :key="index"
            :class="{ active: active === item.name }"
            @mouseover="handleHover(item)"
            @mouseout="handleMouseout(item)"
          >
            <span class="name">{{ item.name }}</span>
            <div class="address mt-16">{{ item.address }}</div>
            <div class="tel mt-16">{{ item.tel }}</div>
          </div>
        </div>
        <div class="map-container ml-32" id="container"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref, shallowRef } from "vue";
import { useMainStore } from "@/store/modules/main.js";
import AMapLoader from "@amap/amap-jsapi-loader";
import store from "@/store/index";
import { storeToRefs } from "pinia";
const location1 = require("@/assets/imgs/location-1.png");
const location2 = require("@/assets/imgs/location-2.png");
const mainStore = useMainStore(store);
const { locale } = storeToRefs(mainStore);
const active = ref(1);
const infos = ref({
  zh: [
    {
      id: 1,
      name: "德清总部",
      address: "浙江省湖州市德清县塔山街科源路10/11F",
      tel: "0572-88889590",
      lnglat: [119.9802482822048, 30.521299738568263],
    },
    {
      id: 2,
      name: "杭州分公司",
      address: "浙江省杭州市西湖区三墩镇紫萱路18号西投绿城·浙谷深蓝中心1栋6F",
      tel: "0571-88845212",
      lnglat: [120.12623327985239, 30.326938548966716],
    },
    {
      id: 3,
      name: "成都分公司",
      address: "四川省成都市金牛区天龙大道1333号6栋10F",
      tel: "028-83222765",
      lnglat: [104.10270814332945, 30.778986995589555],
    },
  ],
  en: [
    {
      id: 1,
      name: "Deqing Headquarter",
      address:
        "10/11F Keyuan Road, Tashan Street, Deqing County, Huzhou City, Zhejiang Province",
      tel: "0572-88889590",
      lnglat: [119.98650475887561, 30.527467557324773],
    },
    {
      id: 2,
      name: "Hangzhou  Office",
      address:
        "6F, Building 1,Xitou Lvcheng Real Blue Center, No.18 Zixuan Road, Sandun Town, Xihu District, Hangzhou, Zhejiang, China",
      tel: "0571-88845212",
      lnglat: [120.13270981961034, 30.333415377988107],
    },
    {
      id: 3,
      name: "Chengdu  Office",
      address:
        "10F, Building 6, No. 1333 Tianlong Avenue, Jinniu District, Chengdu, Sichuan Province",
      tel: "028-83222765",
      lnglat: [104.10270814332945, 30.778986995589555],
    },
  ],
});
let map = shallowRef(null);
let markers = [];
let MapClass = null;
const initMap = () => {
  markers = [];
  MapClass = null;
  AMapLoader.load({
    key: "e1ee2b934954d896bf2bd1afb47485e5", // 申请好的Web端开发者Key，首次调用 load 时必填
    version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
  })
    .then((AMap) => {
      MapClass = AMap;
      map.value = new AMap.Map("container", {
        //设置地图容器id
        viewMode: "3D", //是否为3D地图模式
        zoom: 6, //初始化地图级别
        center: [112.042725, 30.676636], //初始化地图中心点位置
      });
      infos.value[locale.value].forEach((item) => {
        const marker = new AMap.Marker({
          position: item.lnglat,
          icon: new AMap.Icon({
            image: location1,
            size: new AMap.Size(40, 42), //图标所处区域大小
            imageSize: new AMap.Size(40, 42), //图标大小
          }),
          title: item.name,
          zooms: [2, 20],
        });
        markers.push({
          id: item.id,
          name: item.name,
          marker: marker,
          center: item.lnglat,
        });
        map.value.add(marker);
      });
    })
    .catch((e) => {
      console.log(e);
    });
};
const handleHover = ({ id }) => {
  if (!MapClass) return;
  const item = markers.filter((item) => item.id === id)[0];
  item.marker.setIcon(
    new MapClass.Icon({
      image: location2,
      size: new MapClass.Size(40, 52), //图标所处区域大小
      imageSize: new MapClass.Size(40, 52), //图标大小
    })
  );
  item.marker.setLabel({
    content: item.name,
  });
  map.value.setZoom(10);
  map.value.setCenter(item.center);
};
const handleMouseout = ({ id }) => {
  if (!MapClass) return;
  const item = markers.filter((item) => item.id === id)[0];
  item.marker.setIcon(
    new MapClass.Icon({
      image: location1,
      size: new MapClass.Size(40, 42), //图标所处区域大小
      imageSize: new MapClass.Size(40, 42), //图标大小
    })
  );
  item.marker.setLabel({ content: null });
};
onMounted(() => {
  initMap();
});
onBeforeUnmount(() => {
  map.value.destroy();
  map.value = null;
});
</script>

<style lang="scss" scoped>
.title1 {
  font-family: "HarmonyOS Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 39px;
  color: #202632;
}
.methods {
  .methods-inner {
    width: 1400px;
    padding-bottom: 48px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .lists {
    .item {
      width: 400px;
      padding: 40px 32px;
      box-sizing: border-box;
      background: linear-gradient(139.25deg, #f0f4fc 25.16%, #d7e0f2 80.52%);
      border: 1px solid #f4f8ff;
      border-radius: 8px;
      margin-top: 24px;
      cursor: pointer;
      transition: background 0.56s ease-in-out;
      &:first-child {
        margin-top: 0;
      }
      .name {
        font-family: "HarmonyOS Sans";
        font-weight: 700;
        font-size: 28px;
        line-height: 33px;
        color: #243139;
      }
      .address {
        font-family: "HarmonyOS Sans";
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #626773;
        background-image: url("~@/assets/imgs/address.png");
        background-position: top 0;
        background-size: 20px 20px;
        padding-left: 30px;
        background-repeat: no-repeat;
      }
      .tel {
        font-family: "HarmonyOS Sans";
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: justify;
        color: #626773;
        background-image: url("~@/assets/imgs/tel.png");
        background-position: top 0;
        background-size: 20px 20px;
        padding-left: 30px;
        background-repeat: no-repeat;
      }
      &:hover {
        background: radial-gradient(
              68.39% 174.76% at 90.33% 91%,
              rgba(128, 84, 255, 0.8) 0%,
              rgba(58, 137, 255, 0) 100%
            )
            /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
          linear-gradient(139.25deg, #6cceff 25.16%, #0c5dff 80.52%);
        .name,
        .address,
        .tel {
          color: #fff;
        }
        .address {
          background-image: url("~@/assets/imgs/address-white.png");
        }
        .tel {
          background-image: url("~@/assets/imgs/tel-white.png");
        }
      }
    }
  }
  .map-container {
    flex: 1;
  }
}
</style>

<style lang="scss">
.amap-marker-label {
  padding: 6px 16px;
  background: rgba(14, 14, 14, 0.44);
  box-shadow: 0px 2px 8px rgba(86, 86, 86, 0.6);
  border: 1px solid #fff;
  border-left: none;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  color: #fff;
  transform: translate(-20px, -6px);
  z-index: -1;
}
</style>
