/*
 * @Author: ce
 * @Date: 2022-09-06 14:22:56
 * @LastEditors: ce
 * @LastEditTime: 2022-09-09 17:30:56
 * @FilePath: /hitarget-offical/src/views/product/js/index.js
 */
export const product_data = [
  {
    id: 1,
    zh: {
      introduction: {
        name: '三维可视化平台',
        profile: '浙江中海达智慧交通枢纽综合智治平台，基于数字孪生技术及室内外一体化定位技术，打造了一套融合室内外高精度实景三维模型，5G+混合定位、卡口、视联网数据、路网数据的警力在线指挥调度平台，服务于警用的反恐、维稳、保序需求以及民用导航需求。',
        img: require('@/assets/imgs/introduction-1.png')
      },
      advantages: {
        text: '',
        type: '1',
        list: [
          {
            icon: require('@/assets/imgs/advantage-5.png'),
            title: 'DTGlobe',
            text: [
              '支持大范围复杂三维场景高性能可视化',
              '支持多种典型三维空间分析',
              '支持多种特效可视化、支持多种形式三维场景编辑（整平、开挖、透明等）'
            ]
          },
          {
            icon: require('@/assets/imgs/advantage-6.png'),
            title: 'DTServer',
            text: [
              '支持多种服务标准、支持云端与跨平台部署',
              '支持多用户多角色管理、方便不同权限角色对数据的操作',
              '支持场景编辑与分享，多版本场景定制与展示'
            ]
          },
          {
            icon: require('@/assets/imgs/advantage-7.png'),
            title: 'DTBuilder',
            text: [
              '支持多源多格式数据，支撑多样化场景数据集成',
              '支持几何与纹理数据压缩，减少入库后数据量',
              '支持多种空间剖分，提高数据调度性能'
            ]
          }
        ]
      },
      cases: [
        {
          id: 1,
          name: '数字乡村',
          img: require('@/assets/imgs/case-2.png'),
          title: '德清“数字乡村一张图”',
          tips: ['入选浙江省观星台优秀应用', '连续两年获批数字农业农村发展水平全国县域第一', '入选首批国家数字农村试点地区名单', '入选全国数字乡村建设典型案例，位列第一'],
          text: [
            '打造“一图全面感知”的新模式，实现基层服务高效便捷',
            '重塑乡村治理流程，推动自治、法治、德治、智治融合',
            '实现省市县三级数据共享联动，以及县镇村的共享共用',
            '构建服务换积分、积分换服务激励机制'
          ],
          customer: '德清大数据局',
          time: '2021年5月',
          city: '德清',
        },
        {
          id: 2,
          name: '智慧警务',
          img: require('@/assets/imgs/case-3.png'),
          title: '德清数字警务一张图',
          text: '智慧警务综合应用之数字警务一张图项目系统主要以德清县全域的实景三维底图及PGIS地图为基础，集成该区域范围内的各类基础人、地、事、物、情数据，以及静态、动态的警力警情信息数据，构建形成“一图全面感知”警务新模式，并开发警务基础数据上图、智能搜索、警用空间分析、电子沙盘、后台管理等功能，实现“警力可视部署、管理目标清晰明了、应急事件扁平指挥”的社会面治安管控新局面。 ',
          customer: '杭州江干公安',
          time: '2021年5月',
          city: '杭州',
        },
        {
          id: 3,
          name: 'CIM平台',
          img: require('@/assets/imgs/case-99.png'),
          title: '地信小镇 CIM 底座',
          text: '地信小镇 CIM 底座以打造一个与小镇物理世界平行的虚拟数字小镇为目标，通过测绘、扫描以及三维建模等数字化手段在计算机中复刻小镇地上构筑物与地下管网，室外设施设备与室内空间格局，形成小镇物理空间的数字化“复制”；形成地信小镇室内外地上下一体化管理基础平台。构建地信小镇地上城市构筑物、地下管网与人防设施、室内房间格局与室外设施设备一体化的三维场景可视化系统，形成基础可视化与分析管理工具，支撑后序数字孪生应用。',
          customer: '德清地理信息小镇',
          time: '2022年5月',
          city: '德清',
        }
      ],
      cooperationInfo: {
        tel: '0572-88889590',
        qrcode: require('@/assets/imgs/wechat-qrcode.png')
      }
    },
    en: {
      introduction: {
        name: '3D Visualization Platform',
        profile: "Based on digital twin technology and indoor-outdoor integrated positioning technology, Zhejiang Hi Target's intelligent traffic hub comprehensive wise governance platform creates a set of police online command and dispatch platform that integrates indoor and outdoor high-precision real-world 3D models, 5G+ hybrid positioning, chokepoints, video network data, and road network data, serving police needs for anti-terrorism, stability maintenance, and order preservation as well as civilian navigation needs.",
        img: require('@/assets/imgs/introduction-1.png')
      },
      advantages: {
        text: '',
        type: '1',
        list: [
          {
            icon: require('@/assets/imgs/advantage-5.png'),
            title: 'DTGlobe',
            text: [
              'Support high performance visualization of large range of complex 3D scenes',
              'Support a variety of typical 3D space analysis',
              'Support a variety of special effects visualization, support a variety of forms of 3D scene editing (leveling, excavation, transparency, etc.)'
            ]
          },
          {
            icon: require('@/assets/imgs/advantage-6.png'),
            title: 'DTServer',
            text: [
              'Support for multiple service standards, cloud and cross-platform deployment',
              'Support multi-user and multi-role management, facilitate the operation of data by different authority roles',
              'Support scene editing and sharing, multi-version scene customization and display'
            ]
          },
          {
            icon: require('@/assets/imgs/advantage-7.png'),
            title: 'DTBuilder',
            text: [
              'Support multi-source and multi-format data, support diverse scenarios data integration',
              'Support geometry and texture data compression, reduce the amount of data after the library',
              'Support multiple spatial profiles to improve data scheduling performance'
            ]
          }
        ]
      },
      cases: [
        {
          id: 1,
          name: 'Digital Village',
          img: require('@/assets/imgs/case-2.png'),
          title: 'Deqing "Digital Countryside In One Map"',
          tips: ['Selected as an excellent application for stargazing stations in Zhejiang Province', 'For the second year in a row, the county was awarded the first national level of digital agriculture and rural development', 'Selected as one of the first national digital rural pilot areas', 'Selected as a typical case of national digital village construction, ranked first'],
          text: [
            'Create a new model of "one map for comprehensive perception" to realize efficient and convenient grassroots services',
            'Remodeling the rural governance process and promoting the integration of self-governance, rule of law, moral and intellectual governance',
            'Realize data sharing and linkage at provincial, city and county levels, as well as the sharing and sharing of county, town and village',
            'Build service for points and points for service incentive mechanism'
          ],
          customer: 'Deqing Big Data Bureau',
          time: 'May 2021',
          city: 'Deqing',
        },
        {
          id: 2,
          name: 'Intelligent Policing',
          img: require('@/assets/imgs/case-3.png'),
          title: 'Deqing Digital Police One Map',
          text: 'Intelligent police comprehensive application of digital police a map project system is mainly based on the whole area of Deqing County realistic 3D base map and PGIS map, integrated with all kinds of basic people, places, things, things, situations data within the region, as well as static and dynamic police information data, the construction of the formation of "a map of comprehensive perception" police new mode, and the development of basic police data on the map, intelligent search, police spatial analysis, electronic sand table, background management and other functions, to achieve "visible deployment of police, management objectives clear, flat command of emergency events" of the new situation of social security control. ',
          customer: 'Hangzhou Jianggan Public Security',
          time: 'May 2021',
          city: 'Hangzhou',
        },
        {
          id: 3,
          name: 'CIM Platform',
          img: require('@/assets/imgs/case-99.png'),
          title: 'Geocentric Town CIM Base',
          text: `The CIM base of Geosign Town aims to create a virtual digital town parallel to the physical world of the town, replicating the town's above-ground structures and underground pipeline network, outdoor facilities and equipment and indoor space pattern in the computer through digital means such as mapping, scanning and 3D modeling, forming a digital "replica" of the town's physical space; forming The integrated indoor and outdoor above-ground management platform of the town. Build a 3D scene visualization system that integrates above-ground urban structures, underground pipeline networks and human defense facilities, indoor room patterns and outdoor facilities and equipment of the town, and form a basic visualization and analysis management tool to support the digital twin application in the later sequence.`,
          customer: 'Deqing Geographic Information Town',
          time: 'May 2022',
          city: 'Deqing',
        }
      ],
      cooperationInfo: {
        tel: '0572-88889590',
        qrcode: require('@/assets/imgs/wechat-qrcode.png')
      }
    }
  },
  {
    id: 2,
    zh: {
      introduction: {
        name: '倾斜摄影建模软件',
        profile: 'OSketch倾斜摄影测量精细建模软件，可集成多种倾斜摄影影像、地面近景拍摄的影像、无人机成像系统的影像和空三成果，真正实现高效率、高精度的城市精细三维模型建模，提供了一系列交互简洁方便的三维建筑物几何精细快速重建（LOD0，LOD1，LOD3）、建筑物语义自动提取、复杂纹理自动融合、纹理自动映射、智能曲面纹理编辑、自动模型质量控制等专业化、高质量的软件产品及服务。',
        img: require('@/assets/imgs/introduction-2.png')
      },
      advantages: {
        text: '',
        type: '0',
        list: [
          {
            icon: require('@/assets/imgs/advantage-8.png'),
            title: '效率领先',
            text: '基于SketchUp开发，易于上手，建模工具成熟'
          },
          {
            icon: require('@/assets/imgs/advantage-9.png'),
            title: '真实坐标',
            text: '多种软件的空三成果均能导入，坐标无偏移，模型每个点均具备真实地理坐标'
          },
          {
            icon: require('@/assets/imgs/advantage-10.png'),
            title: '异形纹理',
            text: '针对异形纹理及曲面纹理，可对模型面进行整体编辑，纹理支持整体映射整体编辑'
          },
          {
            icon: require('@/assets/imgs/advantage-11.png'),
            title: '全自动映射',
            text: '可选择多种映射级别，多种映射权重，满足客户不同需求，提高纹理映射精准度'
          },
          {
            icon: require('@/assets/imgs/advantage-12.png'),
            title: '轻量导出',
            text: '平均单体模型数据量1MB，支持导出多种格式适，用于多种商业平台'
          }
        ]
      },
      cases2: [
        {
          id: 1,
          name: '实景三维建设',
          img: require('@/assets/imgs/case-4.png')
        },
        {
          id: 2,
          name: '城乡规划、旧城改造',
          img: require('@/assets/imgs/case-5.png')
        },
        {
          id: 3,
          name: '边坡治理',
          img: require('@/assets/imgs/case-6.png')
        },
        {
          id: 4,
          name: '历史文物保护、智慧景区',
          img: require('@/assets/imgs/case-7.png')
        },
        {
          id: 5,
          name: '智慧校园、校园安防',
          img: require('@/assets/imgs/case-8.png')
        }
      ],
      cooperationInfo: {
        tel: '0572-88889590',
        qrcode: require('@/assets/imgs/wechat-qrcode.png')
      }
    },
    en: {
      introduction: {
        name: 'Tilt Photography Modeling Software',
        profile: 'OSketch tilt photogrammetry fine modeling software can integrate a variety of tilt photographic images, images taken by ground close-up, images from UAV imaging systems and air 3 results to truly realize high-efficiency and high-precision urban fine 3D modeling, providing a series of interactive simple and convenient 3D building geometry fine and fast reconstruction (LOD0, LOD1, LOD3), automatic extraction of building semantics, automatic fusion of complex textures, automatic texture mapping, intelligent surface texture editing, automatic model quality control and other specialized, high-quality software products and services.',
        img: require('@/assets/imgs/introduction-2.png')
      },
      advantages: {
        text: '',
        type: '0',
        list: [
          {
            icon: require('@/assets/imgs/advantage-8.png'),
            title: 'Leading Efficiency',
            text: 'Based on SketchUp development, easy to get started and mature modeling tools'
          },
          {
            icon: require('@/assets/imgs/advantage-9.png'),
            title: 'Real Coordinates',
            text: 'The results of a variety of software can be imported, the coordinates are not offset, and each point of the model has real geographic coordinates'
          },
          {
            icon: require('@/assets/imgs/advantage-10.png'),
            title: 'Shaped Texture',
            text: 'For shaped textures and surface textures, the model face can be edited as a whole, and the texture supports overall mapping overall editing'
          },
          {
            icon: require('@/assets/imgs/advantage-11.png'),
            title: 'Fully Automated Mapping',
            text: 'Multiple mapping levels and multiple mapping weights can be selected to meet different customer needs and improve texture mapping accuracy'
          },
          {
            icon: require('@/assets/imgs/advantage-12.png'),
            title: 'Lightweight Export',
            text: 'Average single model data volume of 1MB, support exporting multiple formats suitable for a variety of commercial platforms'
          }
        ]
      },
      cases2: [
        {
          id: 1,
          name: 'Real-world 3D Construction',
          img: require('@/assets/imgs/case-4.png')
        },
        {
          id: 2,
          name: 'Urban And Rural Planning, Old City Renovation',
          img: require('@/assets/imgs/case-5.png')
        },
        {
          id: 3,
          name: 'Slope Management',
          img: require('@/assets/imgs/case-6.png')
        },
        {
          id: 4,
          name: 'Historical Heritage Conservation, Intelligent Scenic Spots',
          img: require('@/assets/imgs/case-7.png')
        },
        {
          id: 5,
          name: 'Smart Campus, Campus Security',
          img: require('@/assets/imgs/case-8.png')
        }
      ],
      cooperationInfo: {
        tel: '0572-88889590',
        qrcode: require('@/assets/imgs/wechat-qrcode.png')
      }
    }
  },
  {
    id: 3,
    zh: {
      introduction: {
        name: 'LINK滤波软件',
        profile: 'Link-DEM 智能滤波与定向精准编辑软件是以数字摄影测量理论为基础，针对目前高分辨率遥感影像、机载激光雷达数据、航空影像密集匹配点云数据等数据特点和现有点云数据处理软件及DEM 生产系统中仍然存在的困难和不足，采用顾及地形弯曲能量的自适应滤波、边界约束的DEM交互式编辑、高精度DEM快速构网与拼接等技术开发的新一代三维点云数据编辑与处理软件。',
        img: require('@/assets/imgs/introduction-3.png')
      },
      advantages: {
        text: '',
        type: '0',
        list: [
          {
            icon: require('@/assets/imgs/advantage-13.png'),
            title: '智能滤波',
            text: '自动化程度高达85%，自适应各种地形特征，无需调参'
          },
          {
            icon: require('@/assets/imgs/advantage-14.png'),
            title: '保特征',
            text: '地形走势滤波后不丢失'
          },
          {
            icon: require('@/assets/imgs/advantage-15.png'),
            title: '灵活组合',
            text: '20余种滤波算法集成可选，顾及地形、植被覆盖、高程约束多种组合'
          },
          {
            icon: require('@/assets/imgs/advantage-16.png'),
            title: '高效计算',
            text: '采用GPU并行加速，计算效率提高10x'
          },
          {
            icon: require('@/assets/imgs/advantage-17.png'),
            title: '2D/3D联动',
            text: '实时刷新，所见即所得'
          },
          {
            icon: require('@/assets/imgs/advantage-18.png'),
            title: '算法领先',
            text: '国际主流滤波算法对比排名第一'
          },
          {
            icon: require('@/assets/imgs/advantage-19.png'),
            title: '全方位辅助检查',
            text: '剖面、控制点、多图层、立体环境'
          },
          {
            icon: require('@/assets/imgs/advantage-20.png'),
            title: '兼容性强',
            text: '便捷对接前端DSM生产软件'
          }
        ]
      },
      cases3: [
        {
          id: 1,
          name: 'DEM生产项目',
          type: '1',
          list: [
            {
              name: '山区林地 — lidar点云',
              img: require('@/assets/imgs/case-9.png')
            },
            {
              name: '城镇房屋 — lidar点云',
              img: require('@/assets/imgs/case-10.png')
            },
            {
              name: '平原 — ZY3',
              img: require('@/assets/imgs/case-11.png')
            }
          ]
        },
        {
          id: 2,
          name: '实景三维及数字乡村项目',
          type: '2',
          list: [
            {
              name: '地形起伏错误与模型冲突',
              img: require('@/assets/imgs/case-12.png')
            },
            {
              name: '地形起伏符合真实情况，地形与模型关系正确',
              img: require('@/assets/imgs/case-14.png')
            }
          ]
        }
      ],
      cooperationInfo: {
        tel: '0572-88889590',
        qrcode: require('@/assets/imgs/wechat-qrcode.png')
      }
    },
    en: {
      introduction: {
        name: 'LINK Filtering Software',
        profile: 'Link-DEM intelligent filtering and directional accurate editing software is based on digital photogrammetry theory, and is a new generation of 3D point cloud data editing and processing software developed with the technology of adaptive filtering taking into account terrain bending energy, interactive editing of DEM with boundary constraint, high precision DEM rapid mesh and splicing, etc. in response to the data characteristics of current high-resolution remote sensing images, airborne LiDAR data, aerial image dense matching point cloud data and the difficulties and shortcomings still exist in existing point cloud data processing software and DEM production system.',
        img: require('@/assets/imgs/introduction-3.png')
      },
      advantages: {
        text: '',
        type: '0',
        list: [
          {
            icon: require('@/assets/imgs/advantage-13.png'),
            title: 'Intelligent Filtering',
            text: 'Automation up to 85%, adaptive to various terrain features, no need to adjust parameters'
          },
          {
            icon: require('@/assets/imgs/advantage-14.png'),
            title: 'Warranty Features',
            text: 'No loss after terrain trend filtering'
          },
          {
            icon: require('@/assets/imgs/advantage-15.png'),
            title: 'Flexible Combinations',
            text: 'More than 20 filtering algorithms can be integrated and selected, taking into account a variety of combinations of terrain, vegetation cover, and elevation constraints'
          },
          {
            icon: require('@/assets/imgs/advantage-16.png'),
            title: 'Efficient Computing',
            text: '10x increase in computational efficiency with GPU parallel acceleration'
          },
          {
            icon: require('@/assets/imgs/advantage-17.png'),
            title: '2D/3D Linkage',
            text: 'Real-time refresh, what you see is what you get'
          },
          {
            icon: require('@/assets/imgs/advantage-18.png'),
            title: 'Algorithm Leadership',
            text: 'Top ranking of international mainstream filtering algorithm comparison'
          },
          {
            icon: require('@/assets/imgs/advantage-19.png'),
            title: 'Full Range Of Ancillary Examinations',
            text: 'Profiles, control points, multiple layers, 3D environments'
          },
          {
            icon: require('@/assets/imgs/advantage-20.png'),
            title: 'High Compatibility',
            text: 'Easy interface to front-end DSM production software'
          }
        ]
      },
      cases3: [
        {
          id: 1,
          name: 'DEM Production Project',
          type: '1',
          list: [
            {
              name: 'Mountain Woodland - Lidar Point Cloud',
              img: require('@/assets/imgs/case-9.png')
            },
            {
              name: 'Townhouses - Lidar Dot Cloud',
              img: require('@/assets/imgs/case-10.png')
            },
            {
              name: 'Plain - ZY3',
              img: require('@/assets/imgs/case-11.png')
            }
          ]
        },
        {
          id: 2,
          name: 'Real Scene 3D and Digital Village Project',
          type: '2',
          list: [
            {
              name: 'Terrain undulation errors and model conflicts',
              img: require('@/assets/imgs/case-12.png')
            },
            {
              name: 'The topographic relief is in line with the real situation, and the terrain is correctly related to the model',
              img: require('@/assets/imgs/case-14.png')
            }
          ]
        }
      ],
      cooperationInfo: {
        tel: '0572-88889590',
        qrcode: require('@/assets/imgs/wechat-qrcode.png')
      }
    }
  },
  {
    id: 4,
    zh: {
      introduction: {
        name: '智慧水务',
        profile: '浙江中海达智慧水务平台基于GIS、物联网、云计算、大数据、人工智能、视觉计算、仿真模拟等技术手段，构建城市水务大脑，实现水务自动化调度、安全运行监测、风险智能研判预警、设施设备巡查养护以及综合运营管理，为水务精细化管理和智慧决策提供支撑。',
        img: require('@/assets/imgs/introduction-4.png')
      },
      advantages: {
        text: '',
        type: '',
        list: [
          {
            icon: require('@/assets/imgs/advantage-21.png'),
            title: '供排水一体化',
            text: '聚焦智慧治污、防洪、排涝、节水、供水，实现水务业务全覆盖'
          },
          {
            icon: require('@/assets/imgs/advantage-22.png'),
            title: '用水安全动态管控',
            text: '集成物联网感知设备、构建水务运行监测体系、保障用水安全'
          },
          {
            icon: require('@/assets/imgs/advantage-23.png'),
            title: '设施运维线上监管',
            text: '实现水务设施安全隐患上报、处置下发、整改修复、作业监管的闭环管理'
          },
          {
            icon: require('@/assets/imgs/advantage-24.png'),
            title: '三维实景综合决策',
            text: '集成水务，设施数据，接入三维实景模型，实现地上地下水务信息立体可视，建设智慧水务决策“工具箱”'
          }
        ]
      },
      cases: [
        {
          id: 1,
          name: '地空智治',
          img: require('@/assets/imgs/case-15.png'),
          title: '金华市地空智治',
          tips: ['浙江省空间治理数字化最佳实践场景'],
          text: '归集地下管线、地下公共停车场、人防工程、地下通道等各类地下空间数据，集成接入前端物联感知设备，构建地上地下一体化数字孪生应用，夯实“城市大脑”数字底座，赋能地下空间治理。',
          customer: '金华',
          time: '2021年12月',
          city: '金华',
        },
        {
          id: 2,
          name: '五水智治',
          img: require('@/assets/imgs/case-16.png'),
          title: '武义五水智治决策平台',
          tips: ['助力武义县获治水大禹鼎'],
          text: '集成一图统管、污水智治、防洪排涝、节水保供、治水指数五大场景，构建科学高效、多跨协同的水环境治理体系，助推县域生态环境品质提档升级。',
          customer: '武义县',
          time: '2022年5月',
          city: '金华',
        },
        {
          id: 3,
          name: '智慧水务',
          img: require('@/assets/imgs/case-17.png'),
          title: '定兴县智慧水务平台',
          text: '建设综合调度系统、管网 GIS 系 统、智慧供水管理平台、数据接入平台、数据中台、营收系统、客服系统、漏损控制系统、 大屏可视化、管理门户等内容，实现从取水、输水、净水到配 水的信息自动化管理，确保居民用水安全。',
          customer: '定兴县水利局',
          time: '2022年5月',
          city: '定兴县',
        }
      ],
      cooperationInfo: {
        tel: '18757181732',
        qrcode: require('@/assets/imgs/wechat-qrcode.png')
      }
    },
    en: {
      introduction: {
        name: 'Smart Water',
        profile: `Based on GIS, Internet of Things, cloud computing, big data, artificial intelligence, visual computing, simulation and other technical means, Zhejiang Hi Target's intelligent water platform builds an urban water brain, realizing automatic water scheduling, safety operation monitoring, risk intelligent research and early warning, facility and equipment inspection and maintenance, and comprehensive operation management, providing support for refined water management and intelligent decision-making.`,
        img: require('@/assets/imgs/introduction-4.png')
      },
      advantages: {
        text: '',
        type: '',
        list: [
          {
            icon: require('@/assets/imgs/advantage-21.png'),
            title: 'Integration Of Water Supply And Drainage',
            text: 'Focus on intelligent pollution control, flood prevention, drainage, water conservation, water supply, to achieve full coverage of water business'
          },
          {
            icon: require('@/assets/imgs/advantage-22.png'),
            title: 'Dynamic Control Of Water Safety',
            text: 'Integrating IOT sensing equipment, building water operation monitoring system, and ensuring water safety'
          },
          {
            icon: require('@/assets/imgs/advantage-23.png'),
            title: 'OnLine Supervision Of Facility Operation And Maintenance',
            text: 'Realizing the closed-loop management of water facilities safety hazards reporting, disposal issuance, rectification and repair, operation supervision'
          },
          {
            icon: require('@/assets/imgs/advantage-24.png'),
            title: '3D Real-world Integrated Decision-making',
            text: 'Integration of water services, facilities data, access to 3D real-world model, to achieve 3D Visualization of above-ground and underground water information, the construction of intelligent water decision-making "toolbox"'
          }
        ]
      },
      cases: [
        {
          id: 1,
          name: ' Smart Governance Of Air-ground',
          img: require('@/assets/imgs/case-15.png'),
          title: 'Jinhua City Ground and Air Smart Governance',
          tips: ['Best practice scenarios for digitalization of spatial governance in Zhejiang Province'],
          text: 'It collects underground pipelines, underground public parking lots, human defense projects, underground channels and other kinds of underground space data, integrates and accesses the front-end IOT sensing equipment, builds an integrated digital twin application above and below ground, solidifies the digital base of "city brain" and empowers underground space governance.',
          customer: 'Jinhua',
          time: 'December 2021',
          city: 'Jinhua',
        },
        {
          id: 2,
          name: 'Five Water Smart Governance',
          img: require('@/assets/imgs/case-16.png'),
          title: 'Wuyi Five Water Wisdom Decision Platform',
          tips: ['Helping Wuyi County win the Water Treatment Dayu Ding'],
          text: 'Integration of a map of unified management, sewage wisdom, flood control and drainage, water conservation and supply, water index five scenarios, the construction of scientific and efficient, multi-straddle synergistic water environment management system to help upgrade the quality of the county ecological environment。',
          customer: 'Wuyi County',
          time: 'May 2022',
          city: 'Jinhua',
        },
        {
          id: 3,
          name: 'Smart Water',
          img: require('@/assets/imgs/case-17.png'),
          title: 'Dingxing County Smart Water Platform',
          text: 'The comprehensive scheduling system, pipeline network GIS system, intelligent water supply management platform, data access platform, data center, revenue collection system, customer service system, leakage control system, large screen visualization and management portal are built to realize automated information management from water intake, transmission, purification to distribution and ensure water safety for residents.',
          customer: 'Dingxing County Water Resources Bureau',
          time: 'May 2022',
          city: 'Dingxing County',
        }
      ],
      cooperationInfo: {
        tel: '18757181732',
        qrcode: require('@/assets/imgs/wechat-qrcode.png')
      }
    }
  },
  {
    id: 5,
    zh: {
      introduction: {
        name: '数字乡村',
        profile: '以“一图全面感知”的全新乡村智治模式为核心，依托大数据、人工智能、物联网、倾斜摄影测量、三维GIS等信息技术，统筹推动自然资源、农业、水利、交通、建设等多部门数据汇聚，整合多规合一、视频监控、基础治理平台，形成“一图一端一中心”的应用支撑体系，构建了全面覆盖乡村规划、经营、环境、服务、监管的乡村治理数字化平台。',
        img: require('@/assets/imgs/introduction-5.png')
      },
      advantages: {
        text: '',
        type: '',
        list: [
          {
            icon: require('@/assets/imgs/advantage-25.png'),
            title: '实景三维地图',
            text: '承载海量网格信息'
          },
          {
            icon: require('@/assets/imgs/advantage-26.png'),
            title: '后台管理中枢',
            text: '动态掌控全域信息'
          },
          {
            icon: require('@/assets/imgs/advantage-27.png'),
            title: '村民互动小程序',
            text: '全面覆盖居民生活'
          }
        ]
      },
      cases: [
        {
          id: 1,
          name: '数字乡村',
          img: require('@/assets/imgs/case-18.png'),
          title: '德清“数字乡村一张图”',
          tips: ['入选浙江省观星台优秀应用', '连续两年获批数字农业农村发展水平全国县域第一', '入选首批国家数字农村试点地区名单', '入选全国数字乡村建设典型案例，位列第一'],
          text: ['打造“一图全面感知”的新模式，实现基层服务高效便捷', '重塑乡村治理流程，推动自治、法治、德治、智治融合', '实现省市县三级数据共享联动，以及县镇村的共享共用', '构建服务换积分、积分换服务激励机制'],
          customer: '德清大数据局',
          time: '2021年5月',
          city: '德清',
        },
        {
          id: 2,
          name: '未来乡村',
          img: require('@/assets/imgs/case-19.png'),
          title: '温州“未来乡村一张图”',
          text: '以国家战略为引领，浙江中海达积极参与乐清市黄华未来乡村的试点建设，围绕产业、风貌、文化、邻里、健康、低碳、交通、智慧、治理等场景为数字乡村建设打造了专属解决方案。',
          customer: '黄华村',
          time: '2021年8月',
          city: '温州',
        },
        {
          id: 3,
          name: '数字乡村综合治理',
          img: require('@/assets/imgs/case-20.png'),
          title: '盐城“数字乡村综合治理平台”',
          text: ['因地制宜发展乡村特色经济', '推进农业农村现代化发展'],
          customer: '秉义村',
          time: '2021年9月',
          city: '盐城',
        }
      ],
      cooperationInfo: {
        tel: '18757181732',
        qrcode: require('@/assets/imgs/wechat-qrcode.png')
      }
    },
    en: {
      introduction: {
        name: 'Digital Village',
        profile: 'With the core of the new rural wisdom governance model of "one map for comprehensive perception", relying on big data, artificial intelligence, Internet of Things, tilt photogrammetry, 3D GIS and other information technologies, we coordinate and promote the convergence of data from multiple departments such as natural resources, agriculture, water conservancy, transportation and construction, integrate multi-regulation, video monitoring and basic governance platforms, form the application support system of "one map, one end and one center", and build a digital platform for rural governance that comprehensively covers rural planning, operation, environment, services and supervision.',
        img: require('@/assets/imgs/introduction-5.png')
      },
      advantages: {
        text: '',
        type: '',
        list: [
          {
            icon: require('@/assets/imgs/advantage-25.png'),
            title: 'Real Scene 3D Map',
            text: 'Carrying massive amounts of grid information'
          },
          {
            icon: require('@/assets/imgs/advantage-26.png'),
            title: 'Back Office Management Hub',
            text: 'Dynamic control of region-wide information'
          },
          {
            icon: require('@/assets/imgs/advantage-27.png'),
            title: 'Villager Interactive Applet',
            text: `Comprehensive coverage of residents' lives`
          }
        ]
      },
      cases: [
        {
          id: 1,
          name: 'Digital Village',
          img: require('@/assets/imgs/case-18.png'),
          title: 'Deqing "Digital Countryside In One Map"',
          tips: ['Selected as an excellent application for stargazing stations in Zhejiang Province','For the second year in a row, it was approved as the first county in the country in terms of digital agriculture and rural development' , 'Selected as one of the first national digital rural pilot areas', 'Selected as a typical case of national digital village construction, ranked 1st'],
          text: ['Create a new model of "one map for comprehensive perception" to realize efficient and convenient grassroots services', 'Remodeling the rural governance process and promoting the integration of self-governance, rule of law, moral and intellectual governance', 'Realize data sharing and linkage at provincial, city and county levels, as well as the sharing and sharing of county, town and village', 'Build service for points and points for service incentive mechanism'],
          customer: 'Deqing Big Data Bureau',
          time: 'May 2021',
          city: 'Deqing',
        },
        {
          id: 2,
          name: 'Future Village',
          img: require('@/assets/imgs/case-19.png'),
          title: 'Wenzhou "Future Countryside In One Map"',
          text: 'With the national strategy as the leader, Zhejiang Hi Target actively participated in the pilot construction of Huang Hua Future Village in Yueqing City, creating exclusive solutions for digital village construction around industry, landscape, culture, neighborhood, health, low-carbon, transportation, wisdom, governance and other scenarios.',
          customer: 'Huang Hua Village',
          time: 'August 2021',
          city: 'Wenzhou',
        },
        {
          id: 3,
          name: 'Integrated Digital Village Management',
          img: require('@/assets/imgs/case-20.png'),
          title: 'Yancheng "digital village comprehensive governance platform"',
          text: ['Develop rural special economy according to local conditions', 'Promote the development of agricultural and rural modernization'],
          customer: 'Bingyi Village',
          time: 'September 2021',
          city: 'Yancheng',
        }
      ],
      cooperationInfo: {
        tel: '18757181732',
        qrcode: require('@/assets/imgs/wechat-qrcode.png')
      }
    }
  },
  {
    id: 6,
    zh: {
      introduction: {
        name: '未来社区',
        profile: '浙江中海达未来社区数字孪生平台，以“物理空间、数字空间和社会空间三空间融合”为核心理念，以浙江省未来社区“三化九场景”为数字化标准，打造创新的、生态的、科技的、智能的、复合的、有内涵的未来社区。首先构建地理信息小镇全域应用能力中心，包括物联感知、数据仓、业务中台、CIM平台、数字孪生五大全域数字引擎，重点打造室内外一体化导航、地信小镇AR场景。',
        img: require('@/assets/imgs/introduction-6.png')
      },
      advantages: {
        text: '',
        type: '',
        list: [
          {
            icon: require('@/assets/imgs/advantage-28.png'),
            title: '未来社区建设框架',
            text: '1个数字底座+3大服务端口+9大场景+N个应用'
          },
          {
            icon: require('@/assets/imgs/advantage-29.png'),
            title: '数字底座',
            text: '4个孪生层次，打造数字孪生城市'
          },
          {
            icon: require('@/assets/imgs/advantage-30.png'),
            title: '三大端口',
            text: '治理端、运营端、服务端全方位覆盖'
          },
          {
            icon: require('@/assets/imgs/advantage-31.png'),
            title: '特色服务',
            text: 'AR楼宇、AR导航、AR广告、AR互动'
          }
        ]
      },
      cases: [
        {
          id: 1,
          name: '百合社区',
          img: require('@/assets/imgs/case-21.png'),
          title: '百合社区建设方案',
          text: '百合未来社区建设旨在将整个地信小镇的全域智治风貌由点到面的呈现出来，打造出具有“地信特色、国际风貌”的数字化未来社区。在百合未来社区的建设中，以一体化、智能化数据平台为支撑，严格按照浙江省未来社区“139+N”的建设框架，通过整合社区人、地、物、事、情、组织等信息，根据百姓最关注的需求，围绕党建、治理、服务、健康、教育、邻里6个重点应用场景，为社区居民提供安全、舒适、智慧的生活环境，实现社区治理、服务能力的全面提升。',
          customer: '百合社区',
          time: '2022年4月',
          city: '德清',
        }
      ],
      cooperationInfo: {
        tel: '18757181732',
        qrcode: require('@/assets/imgs/wechat-qrcode.png')
      }
    },
    en: {
      introduction: {
        name: 'Future Community',
        profile: `Zhejiang Hi Target's future community digital twin platform is based on the core concept of "integration of physical space, digital space and social space", and the digital standard of "three and nine scenes" for the future community of Zhejiang Province, to create an innovative, ecological, technological, intelligent, composite and connotative future community. The future community is innovative, ecological, technological, intelligent, complex and connotative. First of all, we will build a geographic information town-wide application capability center, including IOT perception, data warehouse, business center, CIM platform and digital twin, and focus on creating indoor and outdoor integrated navigation and geographic information town AR scenes.`,
        img: require('@/assets/imgs/introduction-6.png')
      },
      advantages: {
        text: '',
        type: '',
        list: [
          {
            icon: require('@/assets/imgs/advantage-28.png'),
            title: 'Future Community Building Framework',
            text: '1 digital base + 3 major service ports + 9 scenarios + N applications'
          },
          {
            icon: require('@/assets/imgs/advantage-29.png'),
            title: 'Digital Base',
            text: '4 twin levels to create a digital twin city'
          },
          {
            icon: require('@/assets/imgs/advantage-30.png'),
            title: 'Three Major Ports',
            text: 'Full coverage of governance, operation and service side'
          },
          {
            icon: require('@/assets/imgs/advantage-31.png'),
            title: 'Featured Services',
            text: 'AR building, AR navigation, AR advertising, AR interaction'
          }
        ]
      },
      cases: [
        {
          id: 1,
          name: 'Lily Community',
          img: require('@/assets/imgs/case-21.png'),
          title: 'Lily Community Construction Program',
          text: `The construction of Lily Future Community aims at presenting the whole area of Geoxin Town from point to point, and creating a digital future community with "Geoxin characteristics and international style". In the construction of the future community of Lily, the integrated and intelligent data platform is used as the support, in strict accordance with the construction framework of "139+N" of the future community of Zhejiang Province, through the integration of information on people, places, things, matters, situations and organizations in the community, and according to the people's most concerned needs, around the party building, governance, services, health, education, neighborhood 6 It provides a safe, comfortable and intelligent living environment for community residents and realizes the overall improvement of community governance and service capacity.`,
          customer: 'Lily Community',
          time: 'April 2022',
          city: 'Deqing',
        }
      ],
      cooperationInfo: {
        tel: '18757181732',
        qrcode: require('@/assets/imgs/wechat-qrcode.png')
      }
    }
  },
  {
    id: 7,
    zh: {
      introduction: {
        name: '综合枢纽',
        profile: '浙江中海达智慧交通枢纽综合智治平台，基于数字孪生技术及室内外一体化定位技术，打造了一套融合室内外高精度实景三维模型，5G+混合定位、卡口、视联网数据、路网数据的警力在线指挥调度平台，服务于警用的反恐、维稳、保序需求以及民用导航需求。',
        img: require('@/assets/imgs/introduction-7.png')
      },
      advantages: {
        text: '实现一体化指挥调度、一键式联动处置',
        list: [
          {
            icon: require('@/assets/imgs/advantage-1.png'),
            text: '盘活低效人力资源，提高工作效率，减少人力成本'
          },
          {
            icon: require('@/assets/imgs/advantage-2.png'),
            text: '服务于民生“导航、导游、导购”，促进“快进快出”建设'
          },
          {
            icon: require('@/assets/imgs/advantage-3.png'),
            text: '打造“最强四肢”，提高事件响应效率，及时控制事态'
          },
          {
            icon: require('@/assets/imgs/advantage-4.png'),
            text: '实景三维模型和平台可复用、易升级，实现一模多用'
          }
        ]
      },
      cases: [
        {
          id:1,
          name: '杭州东站',
          img: require('@/assets/imgs/case-1.png'),
          title: '杭州东站大型交通枢纽综合智治平台项目',
          text: '浙江中海达智慧交通枢纽综合智治平台，基于数字孪生技术及室内外一体化定位技术，打造了一套融合室内外高精度实景三维模型，5G+混合定位、卡口、视联网数据、路网数据的警力在线指挥调度平台，服务于警用的反恐、维稳、保序需求以及民用导航需求。',
          customer: '杭州江干公安',
          time: '2021年5月',
          city: '杭州'
        },
        {
          id: 2,
          name: '萧山机场',
          img: require('@/assets/imgs/case-22.png'),
          title: '数智航站楼警务平台',
          text: '近年来，航站楼派出所深入贯彻习近平总书记关于反恐怖工作的重要指示精神，准确把握新形势下反恐怖斗争新特点，坚持“三严三安”建设，全力确保反恐怖工作各项措施落到实处、取得实效。浙江中海达视慧空间产业化团队，运用数字化思维、智慧化理念，开发创建了“1+5”数智航站警务平台提供具有真实感体验的全息三维实景地图与精准定位应用服务。数智航站楼警务平台基于“一张可视化立体防控图”，实现航站楼空间化、标准化的全要素融合。',
          customer: '萧山机场',
          time: '2021年3月',
          city: '杭州'
        }
      ],
      cooperationInfo: {
        tel: '18757181732',
        qrcode: require('@/assets/imgs/wechat-qrcode.png')
      }
    },
    en: {
      introduction: {
        name: 'Integrated Hub',
        profile: "Based on digital twin technology and indoor-outdoor integrated positioning technology, Zhejiang Hi Target's intelligent traffic hub integrated wisdom and governance platform creates a set of police online command and dispatch platform that integrates indoor and outdoor high-precision real-world 3D models, 5G+ hybrid positioning, bayonet, video network data, and road network data, serving the needs of police for anti-terrorism, stability maintenance, and order preservation as well as civilian navigation needs.",
        img: require('@/assets/imgs/introduction-1.png')
      },
      advantages: {
        text: 'Realization of integrated command and control, a key linkage disposal',
        list: [
          {
            icon: require('@/assets/imgs/advantage-1.png'),
            text: 'Revitalize inefficient human resources, improve efficiency and reduce labor costs'
          },
          {
            icon: require('@/assets/imgs/advantage-2.png'),
            text: 'Service in the livelihood of the "navigation, guide, shopping guide", to promote the "fast in and fast out" construction'
          },
          {
            icon: require('@/assets/imgs/advantage-3.png'),
            text: 'Build the "strongest limbs" to improve the efficiency of incident response and control the situation in a timely manner'
          },
          {
            icon: require('@/assets/imgs/advantage-4.png'),
            text: 'Real-world 3D models and platforms are reusable and easily upgradable, realizing multiple uses of one model'
          }
        ]
      },
      cases: [
        {
          id: 1,
          name: 'Hangzhou East Railway Station',
          img: require('@/assets/imgs/case-1.png'),
          title: 'Hangzhou East Station Large Traffic Hub Comprehensive Intelligent Governance Platform Project',
          text: "Based on digital twin technology and indoor-outdoor integrated positioning technology, Zhejiang Hi Target's intelligent traffic hub integrated wisdom and governance platform creates a set of police online command and dispatch platform that integrates indoor and outdoor high-precision real-world 3D models, 5G+ hybrid positioning, bayonet, video network data, and road network data, serving the needs of police for anti-terrorism, stability maintenance, and order preservation as well as civilian navigation needs.",
          customer: 'Hangzhou Jianggan Public Security',
          time: 'May 2021',
          city: 'Hangzhou',
          cooperationInfo: {
            tel: '0572-88845214',
            qrcode: require('@/assets/imgs/wechat-qrcode.png')
          }
        },
        {
          id: 2,
          name: 'Xiaoshan Airport',
          img: require('@/assets/imgs/case-22.png'),
          title: 'Digital Intelligence Terminal Police Platform',
          text: `In recent years, the terminal police station to carry out the spirit of General Secretary Xi Jinping's important instructions on anti-terrorist work, accurately grasp the new situation under the new features of the fight against terrorism, adhere to the "three strict three security" construction, to ensure that the anti-terrorist work measures into practice, to achieve effective results. Zhejiang Hi Target's industrialization team, using digital thinking and wisdom concept, developed and created the "1+5" digital intelligence terminal police platform to provide holographic 3D real-world maps and accurate positioning application services with realistic experience. Based on the "one visualized three-dimensional prevention and control map", the Digital Intelligence Terminal Police Platform realizes the spatial and standardized integration of all elements of the terminal.`,
          customer: 'Xiaoshan Airport',
          time: 'March 2021',
          city: 'Hangzhou'
        }
      ],
      cooperationInfo: {
        tel: '18757181732',
        qrcode: require('@/assets/imgs/wechat-qrcode.png')
      }
    }
  },
  {
    id: 8,
    zh: {
      introduction: {
        name: '工程测量',
        profile: '控制测量、地形测量、竣工测量、变形观测、建筑工程测量、水利工程测量、铁路测量、公路测量、桥梁工程测量、隧道工程测量、矿山测量、城市市政工程测量等测绘工作。完成了多地数字地理空间框架设计及建设、大比例地形图测绘、土地勘测等测绘项目。',
        img: require('@/assets/imgs/introduction-8.png')
      },
      advantages: {
        text: '',
        type: '',
        list: [
          {
            icon: require('@/assets/imgs/advantage-32.png'),
            title: '控制测量',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-33.png'),
            title: '地形测量',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-34.png'),
            title: '竣工测量',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-35.png'),
            title: '变形观测',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-36.png'),
            title: '建筑工程测量',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-37.png'),
            title: '水利工程测量',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-38.png'),
            title: '铁路测量',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-39.png'),
            title: '公路测量',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-40.png'),
            title: '桥梁工程测量',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-41.png'),
            title: '隧道工程测量',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-42.png'),
            title: '矿山测量',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-43.png'),
            title: '城市市政工程测量',
            text: ''
          }
        ]
      },
      cases: [],
      cooperationInfo: {
        tel: '18667909532',
        qrcode: require('@/assets/imgs/wechat-qrcode.png')
      }
    },
    en: {
      introduction: {
        name: 'Engineering Surveying',
        profile: 'Control survey, topographic survey, as-built survey, deformation observation, construction engineering survey, water conservancy engineering survey, railroad survey, highway survey, bridge engineering survey, tunnel engineering survey, mine survey, urban municipal engineering survey and other surveying and mapping work. We have completed the design and construction of digital geospatial framework in many places, large scale topographic map mapping, land survey and other surveying and mapping projects.',
        img: require('@/assets/imgs/introduction-8.png')
      },
      advantages: {
        text: '',
        type: '',
        list: [
          {
            icon: require('@/assets/imgs/advantage-32.png'),
            title: 'Control measurements',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-33.png'),
            title: 'Topographic survey',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-34.png'),
            title: 'As-built measurement',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-35.png'),
            title: 'Deformation observation',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-36.png'),
            title: 'Building Engineering Surveying',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-37.png'),
            title: 'Hydraulic Engineering Survey',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-38.png'),
            title: 'Railroad Surveying',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-39.png'),
            title: 'Highway Surveying',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-40.png'),
            title: 'Bridge Engineering Survey',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-41.png'),
            title: 'Tunnel Engineering Survey',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-42.png'),
            title: 'Mine Surveying',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-43.png'),
            title: 'Urban Municipal Engineering Survey',
            text: ''
          }
        ]
      },
      cases: [],
      cooperationInfo: {
        tel: '18667909532',
        qrcode: require('@/assets/imgs/wechat-qrcode.png')
      }
    }
  },
  {
    id: 9,
    zh: {
      introduction: {
        name: '地下空间测绘',
        profile: '服务能力涵盖地下管线探测及地下空间测绘全业务需求。具有多型号地下管线探测仪、探地雷达、三维激光扫描仪、cctv检测设备等地下空间探测设备，承接了萧山区、临平区、成都市金牛区、盖州市、额尔古纳市(内蒙)、穆棱市、林口县、泰州市、江山市、开化县、抚远市、政和县、仁寿县等多地地下设施/地下空间普查。',
        img: require('@/assets/imgs/introduction-9.png')
      },
      advantages: {
        text: '',
        type: '',
        list: []
      },
      cases: [],
      cooperationInfo: {
        tel: '18667909532',
        qrcode: require('@/assets/imgs/wechat-qrcode.png')
      }
    },
    en: {
      introduction: {
        name: 'Underground Space Mapping',
        profile: 'Service capacity covers the whole business needs of underground pipeline detection and underground space mapping. With underground space detection equipment such as multi-model underground pipeline detector, ground search radar, 3D laser scanner, cctv detection equipment, we have undertaken underground facilities/underground space census in Xiaoshan District, Linping District, Jinniu District of Chengdu City, Gaili City, Ergun City (Inner Mongolia), Muling City, Linkou County, Taizhou City, Jiangshan City, Kaihua County, Fuyuan City, Zhenghe County, Renshou County and many other places.',
        img: require('@/assets/imgs/introduction-9.png')
      },
      advantages: {
        text: '',
        type: '',
        list: []
      },
      cases: [],
      cooperationInfo: {
        tel: '18667909532',
        qrcode: require('@/assets/imgs/wechat-qrcode.png')
      }
    }
  },
  {
    id: 10,
    zh: {
      introduction: {
        name: '界线与不动产测绘',
        profile: '行政区域界线测绘，地籍测绘、房产测绘、海域权属测绘等不动产测绘，不动产测绘监理。完成了杭州市临安区、萧山区、富阳区、义乌市、兰溪市、武义县、江山市、龙游县、缙云县、简阳市等地不动产测绘共计约7000万平方米以上，并全部通过浙江省测绘质量监督检验站验收。',
        img: require('@/assets/imgs/introduction-10.png')
      },
      advantages: {
        text: '',
        type: '1',
        list: [
          {
            icon: require('@/assets/imgs/advantage-44.png'),
            title: '行政区域界线测绘',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-45.png'),
            title: '地籍不动产测绘',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-46.png'),
            title: '房产不动产测绘',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-47.png'),
            title: '海域权属不动产测绘',
            text: ''
          }
        ]
      },
      cases: [],
      cooperationInfo: {
        tel: '18667909532',
        qrcode: require('@/assets/imgs/wechat-qrcode.png')
      }
    },
    en: {
      introduction: {
        name: 'Boundary And Real Estate Mapping',
        profile: "Administrative area boundary mapping, cadastral mapping, property mapping, sea area ownership mapping and other real estate mapping, real estate mapping supervision. We have completed real estate surveying and mapping in Lin'an District, Xiaoshan District, Fuyang District, Yiwu City, Lanxi City, Wuyi County, Jiangshan City, Longyou County, Jinyun County, Jinyang City, etc., totaling about 70 million square meters or more, and all of them have passed the inspection and acceptance of Zhejiang Province Surveying and Mapping Quality Supervision and Inspection Station.",
        img: require('@/assets/imgs/introduction-10.png')
      },
      advantages: {
        text: '',
        type: '1',
        list: [
          {
            icon: require('@/assets/imgs/advantage-44.png'),
            title: 'Administrative Boundary Mapping',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-45.png'),
            title: 'Cadastral Real Estate Mapping',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-46.png'),
            title: 'Real Estate Real Estate Mapping',
            text: ''
          },
          {
            icon: require('@/assets/imgs/advantage-47.png'),
            title: 'Real Estate Mapping Of Maritime Tenure',
            text: ''
          }
        ]
      },
      cases: [],
      cooperationInfo: {
        tel: '18667909532',
        qrcode: require('@/assets/imgs/wechat-qrcode.png')
      }
    }
  },
  {
    id: 11,
    zh: {
      introduction: {
        name: '测绘航空摄影',
        profile: '公司具备多型号无人机测量系统，可搭载不同测量设备。以倾斜摄影为基础，具备机载雷达、高光谱相机等设备，形成了完善的航空摄影产品生产、质检及管理流程。完成了多地可测量实景地图、高清街景、空中全景、三维模型及4D产品的生产、应用。',
        img: require('@/assets/imgs/introduction-11.png')
      },
      advantages: {
        text: '',
        type: '1',
        list: [ ]
      },
      cases: [],
      cooperationInfo: {
        tel: '18667909532',
        qrcode: require('@/assets/imgs/wechat-qrcode.png')
      }
    },
    en: {
      introduction: {
        name: 'Mapping Aerial Photography',
        profile: 'The company is equipped with multiple types of UAV measurement systems, which can carry different measurement equipment. Based on tilt photography and equipped with airborne radar and hyperspectral camera, we have formed a perfect production, quality inspection and management process of aerial photography products. We have completed the production and application of multi-location measurable real-world maps, high-definition street scenes, aerial panoramas, 3D models and 4D products.',
        img: require('@/assets/imgs/introduction-11.png')
      },
      advantages: {
        text: '',
        type: '1',
        list: [ ]
      },
      cases: [],
      cooperationInfo: {
        tel: '18667909532',
        qrcode: require('@/assets/imgs/wechat-qrcode.png')
      }
    }
  }
]