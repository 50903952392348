<template>
  <div id="cooperation" class="cooperation pb-60 mt-48 flex content-center">
    <div class="cooperation-inner">
      <div class="title main-title">{{ $t('contact.title1') }}</div>
      <div class="item mt-32" v-for="item in infos[locale]" :key="item.id">
        <div class="item-name flex content-between items-center">
          <span class="name">{{ item.name }}</span>
          <div class="tel">
            <span class="text">{{ $t('contact.label1') }}</span> {{ item.tel }}
          </div>
        </div>
        <!-- <div class="list flex items-center flex-wrap" v-if="item.list.length">
          <div class="list-item flex content-between items-center" v-for="(z, zIndex) in item.list" :key="zIndex">
            <div class="flex flex-vertical">
              <span class="label">{{ z.name }}</span>
              <div class="tel mt-24">
                <span class="text">{{ $t('contact.label1') }} </span>
                {{ z.tel }}
              </div>
            </div>
            <div class="qr-code h-80 w-80">
              <img class="w-full h-full" :src="z.qrcode" alt="">
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue'
  import { useMainStore } from "@/store/modules/main.js";
  import store from '@/store/index'
  import { storeToRefs } from 'pinia';
  const mainStore = useMainStore(store)
  const { locale } = storeToRefs(mainStore)
  const infos = ref({
    zh: [
      {
        id: 1,
        name: '三维可视化',
        tel: '0572-88889590',
        list: [
          {
            name: '三维可视化平台',
            tel: '18988982827',
            qrcode: require('@/assets/imgs/wechat-qrcode.png')
          },
          {
            name: '倾斜摄影建模软件',
            tel: '18988982827',
            qrcode: require('@/assets/imgs/wechat-qrcode.png')
          },
          {
            name: 'LINK滤波软件',
            tel: '18988982827',
            qrcode: require('@/assets/imgs/wechat-qrcode.png')
          }
        ]
      },
      {
        id: 2,
        name: '智慧城市',
        tel: '18757181732',
        list: [
          {
            name: '智慧排水',
            tel: '18988982827',
            qrcode: require('@/assets/imgs/wechat-qrcode.png')
          },
          {
            name: '智慧供水',
            tel: '18988982827',
            qrcode: require('@/assets/imgs/wechat-qrcode.png')
          },
          {
            name: '数字乡村',
            tel: '18988982827',
            qrcode: require('@/assets/imgs/wechat-qrcode.png')
          },
          {
            name: '未来社区',
            tel: '18988982827',
            qrcode: require('@/assets/imgs/wechat-qrcode.png')
          },
          {
            name: '综合枢纽',
            tel: '18988982827',
            qrcode: require('@/assets/imgs/wechat-qrcode.png')
          },
          {
            name: 'CIM平台',
            tel: '18988982827',
            qrcode: require('@/assets/imgs/wechat-qrcode.png')
          }
        ]
      },
      {
        id: 3,
        name: '测绘业务',
        tel: '18667909532',
        list: []
      },
      {
        id: 4,
        name: '更多业务接洽',
        tel: '0572-8889590',
        list: []
      }
    ],
    en: [
      {
        id: 1,
        name: '3D Visualization',
        tel: '0572-88889590',
        list: [
          {
            name: '3D Visualization platform',
            tel: '18988982827',
            qrcode: require('@/assets/imgs/wechat-qrcode.png')
          },
          {
            name: 'Tilt Photography Modeling Moftware',
            tel: '18988982827',
            qrcode: require('@/assets/imgs/wechat-qrcode.png')
          },
          {
            name: 'LINK Filtering Software',
            tel: '18988982827',
            qrcode: require('@/assets/imgs/wechat-qrcode.png')
          }
        ]
      },
      {
        id: 2,
        name: 'Smart City',
        tel: '18757181732',
        list: [
          {
            name: 'Smart Drainage',
            tel: '18988982827',
            qrcode: require('@/assets/imgs/wechat-qrcode.png')
          },
          {
            name: 'Smart Water Supply',
            tel: '18988982827',
            qrcode: require('@/assets/imgs/wechat-qrcode.png')
          },
          {
            name: 'Digital Village',
            tel: '18988982827',
            qrcode: require('@/assets/imgs/wechat-qrcode.png')
          },
          {
            name: 'Future Community',
            tel: '18988982827',
            qrcode: require('@/assets/imgs/wechat-qrcode.png')
          },
          {
            name: 'Integrated Hub',
            tel: '18988982827',
            qrcode: require('@/assets/imgs/wechat-qrcode.png')
          },
          {
            name: 'CIM Platform',
            tel: '18988982827',
            qrcode: require('@/assets/imgs/wechat-qrcode.png')
          }
        ]
      },
      {
        id: 3,
        name: 'Surveying and Mapping Business',
        tel: '18667909532',
        list: []
      },
      {
        id: 4,
        name: 'For More Business Contacts',
        tel: '0572-8889590',
        list: []
      }
    ]
  })
</script>

<style lang="scss" scoped>
  .cooperation {
    width: 100%;
    .title {
      font-family: 'HarmonyOS Sans';
      font-weight: 700;
      font-size: 33px;
      line-height: 39px;
      color: #202632;
    }
    .cooperation-inner {
      max-width: 1400px;
      width: 100%;
      .item {
        .item-name  {
          padding: 20px;
          background: #F5F9FF;
          .name {
            font-family: 'HarmonyOS Sans';
            font-size: 28px;
            line-height: 33px;
            text-align: justify;
            color: #202632;
          }
          .tel {
            padding-left: 24px;
            background-image:  url('~@/assets/imgs/tel.png');
            background-position: left 0;
            background-size: 20px 20px;
            background-repeat: no-repeat;
            font-family: 'HarmonyOS Sans';
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #202632;
            .text {
              color: #626773;
            }
          }
        }
        .list {
          padding: 24px;
          background: #F4F6F9;
          .list-item {
            width:429px;
            padding: 24px;
            box-sizing: border-box;
            background: #FFFFFF;
            border: 1px solid rgba(118, 130, 155, 0.1);
            border-radius: 8px;
            margin-right: 32px;
            &:nth-child(3n) {
              margin-right: 0;
            }

            &:nth-child(n + 4) {
              margin-top: 32px;
            }
            .label {
              font-family: 'HarmonyOS Sans';
              font-weight: 500;
              font-size: 18px;
              line-height: 21px;
              color: #202632;
            }
            .tel {
              padding-left: 24px;
              background-image:  url('~@/assets/imgs/tel.png');
              background-position: left 0;
              background-size: 20px 20px;
              background-repeat: no-repeat;
              font-family: 'HarmonyOS Sans';
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              color: #202632;
              .text {
                color: #626773;
              }
            }
          }
        }
      }
    }
  }
</style>