<!--
 * @Author: ce
 * @Date: 2022-09-08 11:17:29
 * @LastEditors: ce
 * @LastEditTime: 2022-09-08 17:43:57
 * @FilePath: /hitarget-offical/src/views/product/components/business.vue
-->
<template>
  <div class="business mt-48">

    <h2 class="title1 main-title">{{ $t('product.title2') }}</h2>
    <div class="flex mt-32">
      <div class="bussiness-item w-600 h-190 mr-20">
        <div class="title">{{ $t('product.label7') }}</div>
      </div>
      <div class="bussiness-item w-600 h-190">
        <div class="title">{{ $t('product.label8') }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
.title1 {
  font-family: 'HarmonyOS Sans';
  font-weight: 700;
  font-size: 33px;
  line-height: 39px;
  color: #202632;
}
  .business {
    .bussiness-item {
      position: relative;
      background-position: center center;
      background-size: 572px 190px;
      background-repeat: no-repeat;
      background-color:  #F1F5FF;
      border-radius: 4px;
      &:first-child {
        background-image: url('~@/assets/imgs/business-1.png');
      }
      &:last-child {
        background-image: url('~@/assets/imgs/business-2.png');
      }
      .title {
        position: absolute;
        left: 40px;
        top: 50%;
        transform: translateY(-50%);
        font-family: 'Source Han Sans CN';
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        text-align: justify;
        color: #202632;
        width: 10em;
        &::after {
          display: block;
          content: '';
          position: absolute;
          left: 0;
          bottom: -12px;
          width: 22px;
          border-bottom: 3px solid #0055FF;
        }
      }
    }
  }
</style>
<style lang="scss">

@media screen and (max-width: 1600px) {
  .business {
    .bussiness-item {
      width: 540px;
    }
  }
}
</style>