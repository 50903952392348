<!--
 * @Author: ce
 * @Date: 2022-09-08 13:46:24
 * @LastEditors: ce
 * @LastEditTime: 2022-09-08 17:47:49
 * @FilePath: /hitarget-offical/src/views/product/components/project.vue
-->
<template>
  <div class="project mt-48">
    <span class="title1 main-title">{{ $t('product.title3') }}</span>
    <div class="img mt-32" :style="{ backgroundImage: `url(${ infos[locale] })` }">
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue'
  import { useMainStore } from "@/store/modules/main.js";
  import store from '@/store/index'
  import { storeToRefs } from 'pinia';
  const mainStore = useMainStore(store)
  const { locale } = storeToRefs(mainStore)
  const infos = ref({
    zh: require('@/assets/imgs/project-1.png'),
    en: require('@/assets/imgs/project-2.png')
  })
</script>

<style lang="scss" scoped>
  .title1 {
    font-family: 'HarmonyOS Sans';
    font-weight: 700;
    font-size: 33px;
    line-height: 39px;
    color: #202632;
  }
  .project {
    .img {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      height: 257px;
      background-size: 860px 207px;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
</style>