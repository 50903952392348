<template>
  <div class="head-img"></div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
  .head-img {
    height: 670px;
    background-image: url('~@/assets/imgs/contact-1.png');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
</style>