<!--
 * @Author: ce
 * @Date: 2022-09-06 15:26:28
 * @LastEditors: ce
 * @LastEditTime: 2022-09-09 09:36:31
 * @FilePath: /hitarget-offical/src/views/product/components/case3.vue
-->
<template>
  <div class="case mt-48 flex content-center">
    <div class="case-inner">
      <h2 class="title main-title">{{ $t('product.title2') }}</h2>
      <div class="case-content mt-32">
        <div class="case-tabs">
          <div class="tab-list flex items-center">
            <div class="tab-item" v-for="(item, index) in data" :key="index" @click="handleClick(item)" :class="{ active: active === item.id }">
              <span>{{ item.name }}</span>
            </div>
          </div>
          <template v-for="(item, index) in data" :key="index">
            <div class="tab-content mt-16 pb-48 " v-if="item.id === active">
              <div class="type1 flex flex-wrap" v-if="item.type === '1'">
                <div class="type-item w-393 h-229" v-for="(z, zIndex) in item.list" :key="zIndex">
                  <div class="img w-full h-full">
                    <img class="w-full h-full block" :src="z.img" alt="">
                  </div>
                  <span class="label block mt-12">{{ z.name }}</span>
                </div>
              </div>
              <div class="type2 flex flex-wrap" v-else>
                <div class="type-item w-450 h-210">
                  <div class="img w-full h-full">
                    <img class="block w-full h-full" :src="item.list[0].img" alt="">
                  </div>
                  <span class="name block w-full">{{ item.list[0].name }}</span>
                </div>
                <div class="arrow w-320 h-210 flex items-center content-between">
                  <span>{{ $t('product.label9') }}</span>
                  <span>{{ $t('product.label10') }}</span>
                </div>
                <div class="type-item w-450 h-210">
                  <div class="img w-full h-full">
                    <img class="block w-full h-full" :src="item.list[1].img" alt="">
                  </div>
                  <span class="name block w-full">{{ item.list[1].name }}</span>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="consultation flex items-center content-between mt-48"> 
        <div class="left flex items-center content-between">
          <div class="img h-160 w-160">
            <img class="w-full h-full" :src="require('@/assets/imgs/consultation-1.png')" alt="">
          </div>
          <div class="ml-10">
            <p class="label">{{ $t('product.label4') }}</p>
            <p class="tel mt-10">{{ cooperationInfo.tel }}</p>
          </div>
        </div>
        <div class="right flex flex-vertical items-center">
          <img class="h-100 w-100" :src="cooperationInfo.qrcode" alt="">
          <span class="scan">{{ $t('product.label5') }}</span>
          <!-- <span class="label">{{ $t('product.label6') }}</span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup> 
  // eslint-disable-next-line no-undef
  defineProps(['data', 'cooperationInfo'])
  import { ref } from 'vue'
  const active = ref(1)
  const handleClick = ({ id }) => {
    active.value = id
  }
</script>

<style lang="scss" scoped>
  .list-text {
    li {
      font-family: 'Source Han Sans CN';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #626773;
      padding-left: 14px;
      background-image: url('~@/assets/imgs/list-1.png');
      background-size: 4px 4px;
      background-position: left center;
      background-repeat: no-repeat;
    }
  }
  .tips {
    .tip-item {
      font-family: 'Source Han Sans CN';
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      color: #4E82EC;
      width: 293px;
      padding: 21px 0px 10px;
      box-sizing: border-box;
      margin-right:6px;
      background-image: url('~@/assets/imgs/tip-1.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center center;
      &:nth-child(2n) {
        margin: 0;
      }
      &:nth-child(n+3) {
        margin-top: 11px;
      }
    }
  }
  .case {
    width: 100%;
    .case-inner {
      max-width: 1220px;
    }
    .title {
      font-family: 'HarmonyOS Sans';
      font-weight: 700;
      font-size: 33px;
      line-height: 39px;
      color: #202632;
    }
    .case-content {
      .case-tabs {
        .tab-list {
          .tab-item {
            font-family: 'Source Han Sans CN';
            font-size: 20px;
            line-height: 30px;
            color: #626773;
            padding: 0 24px;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            cursor: pointer;
            span {
              transition: .28s;
            }
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              border: none;
            }
            &.active {
              font-weight: 700;
              font-size: 28px;
              color: #0055FF;
              span {
                transform: scale(1.1);
              }
            }
          }
        }
        .tab-content {
          border-bottom: 1px solid rgba(0, 0 ,0 , .1);
          .type1 {
            .type-item {
              text-align: center;
              margin-right: 20px;
              &:last-child {
                margin-right: 0;
              }
              .label {
                font-family: 'Source Han Sans CN';
                font-size: 16px;
                line-height: 19px;
                color: #626773;
              }
            }
          }
          .type2 {
            .arrow {
              background-image: url('~@/assets/imgs/case-13.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-position: center center;
              padding: 0 46px;
              font-family: 'Source Han Sans CN';
              font-weight: 500;
              font-size: 18px;
              line-height: 19px;
              text-align: center;
              color: #247BEC;
              box-sizing: border-box;
            }
            .type-item {
              position: relative;
              .name {
                position: absolute;
                left: 0;
                bottom: 0;
                padding: 12px 0;
                font-family: 'Source Han Sans CN';
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                color: #FFFFFF;
                background: linear-gradient(0deg, rgba(56, 119, 245, 0.1), rgba(56, 119, 245, 0.1)), linear-gradient(180deg, rgba(69, 89, 131, 0) 0%, rgba(40, 51, 74, 0.42) 100%);
              }
            }
          }
        }
      }
    }
  }

.consultation {
  height: 184px;
  border: 1px solid #DBE3F4;
  border-radius: 8px;
  padding: 12px 16px;
  .left {
    .label {
      font-family: 'HarmonyOS Sans';
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: 0.05em;
      color: #0055FF;
    }
    .tel {
      font-family: 'HarmonyOS Sans';
      font-style: italic;
      font-weight: 900;
      font-size: 44px;
      line-height: 53px;
      letter-spacing: 0.1em;
      color: #313743;
    }
  }
  .right {
    .scan {
      font-family: 'HarmonyOS Sans';
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.05em;
      color: #202632;
    }
    .label {
      font-family: 'HarmonyOS Sans';
      font-size: 12px;
      line-height: 28px;
      color: #626773;
    }
  }
}

</style>
<style lang="scss">

@media screen and (max-width: 1600px) {
    .case {
      .case-inner {
        .type1 {
          .type-item {
            width: 313px !important;
          }
        }
        .type2 {
          .type-item {
            width: 340px !important;
          }
        }
      } 
    }
  }

</style>