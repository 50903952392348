<!--
 * @Author: ce
 * @Date: 2022-09-08 11:46:34
 * @LastEditors: ce
 * @LastEditTime: 2022-09-09 11:50:36
 * @FilePath: /hitarget-offical/src/views/product/components/applications.vue
-->
<template>
  <div class="applications mt-48">
    <span class="title1 main-title">{{ $t('product.title4') }}</span>
    <template v-for="(item, index) in infos[locale]" :key="item.index">
      <div class="application-item flex items-start" v-if="!(index%2)">
        <div class="info flex content-end items-end flex-vertical">
          <span class="index">{{ item.index }}</span>
          <span class="name">{{ item.name }}</span>
          <img class="h-16 w-53 mt-12" :src="require('@/assets/imgs/application-arrow.png')" alt="">
        </div>
        <div class="img h-300 w-470 ml-20">
          <img class="h-full w-full" :src="item.img" alt="">
        </div>
      </div>
      <div class="application-item flex items-start" v-else>
        <div class="img h-300 w-470 mr-20">
          <img class="w-full h-full" :src="item.img" alt="">
        </div>
        <div class="info flex items-start flex-vertical">
          <span class="index">{{ item.index }}</span>
          <span class="name">{{ item.name }}</span>
          <img class="h-16 w-53 trans mt-12" :src="require('@/assets/imgs/application-arrow.png')" alt="">
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
 import { ref } from 'vue'
  import { useMainStore } from "@/store/modules/main.js";
  import store from '@/store/index'
  import { storeToRefs } from 'pinia';
  const mainStore = useMainStore(store)
  const { locale } = storeToRefs(mainStore)
  const infos = ref({
    zh: [
      {
        name: '可测量实景地图',
        index: '01',
        img: require('@/assets/imgs/application-1.png')
      },
      {
        name: '高清街景',
        index: '02',
        img: require('@/assets/imgs/application-2.png')
      },
      {
        name: '空中全景',
        index: '03',
        img: require('@/assets/imgs/application-3.png')
      },
      {
        name: '三维模型',
        index: '04',
        img: require('@/assets/imgs/application-4.png')
      },
      {
        name: '4D产品的生产、应用',
        index: '05',
        img: require('@/assets/imgs/application-5.png')
      }
    ],
    en: [
      {
        name: 'Measurable live map',
        index: '01',
        img: require('@/assets/imgs/application-1.png')
      },
      {
        name: 'HD Street View',
        index: '02',
        img: require('@/assets/imgs/application-2.png')
      },
      {
        name: 'Air Panorama',
        index: '03',
        img: require('@/assets/imgs/application-3.png')
      },
      {
        name: '3D model',
        index: '04',
        img: require('@/assets/imgs/application-4.png')
      },
      {
        name: 'Production and application of 4D products',
        index: '05',
        img: require('@/assets/imgs/application-5.png')
      }
    ]
  })
</script>

<style lang="scss" scoped>
.title1 {
  font-family: 'HarmonyOS Sans';
  font-weight: 700;
  font-size: 33px;
  line-height: 39px;
  color: #202632;
}
 .applications {
    position: relative;
    height: 1138px;
    .application-item {
      position: absolute;
      .info {
        .index {
          font-family: 'YouSheBiaoTiHei';
          font-style: normal;
          font-weight: 400;
          font-size: 46px;
          line-height: 60px;
          background: linear-gradient(180deg, rgba(78, 130, 236, 0.6) 14.71%, rgba(78, 130, 236, 0) 76.47%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          opacity: 0.5;
        }
        .name {
          font-family: 'Source Han Sans CN';
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #202632;
          max-width: 170px;
        }
        .trans {
          transform: rotate(180deg);
        }
      }
      &:nth-child(2) {
        right: 0;
        top: 40px;
      }
      &:nth-child(3) {
        left: 0;
        top: 240px;
      } 
      &:nth-child(4) {
        right: 0;
        top: 440px;
      }
      &:nth-child(5) {
        left: 0;
        top: 640px;
      }
      &:nth-child(6) {
        right: 0;
        top: 840px;
      }
    }
 }
</style>