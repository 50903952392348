<!--
 * @Author: ce
 * @Date: 2022-09-06 13:38:55
 * @LastEditors: ce
 * @LastEditTime: 2022-09-09 17:32:44
 * @FilePath: /hitarget-offical/src/views/product/components/productList.vue
-->
<template>
  <div class="products">
    <span class="title1">{{ $t('product.title4') }}</span>
    <div class="group-list mt-32">
        <div class="product-group pt-24 mt-32" v-for="(group, index) in infos[locale]" :key="index">
          <div class="group-name flex items-center">
            <img :src="group.icon1" alt="" class="h-28 w-28">
            <span class="name ml-8">{{ group.title }}</span>
          </div>
          <div class="product-list">
            <div 
              class="product-item mt-24" 
              :class="{ 'active': productId === item.id }" 
              v-for="(item, zIndex) in group.list" 
              :key="zIndex"
              @click="handleClick(item)"
              >
              <span :class="{ 'main-title': productId === item.id }"> {{ item.title }}</span>
             </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue'
   import { useMainStore } from "@/store/modules/main.js";
  import store from '@/store/index'
  import { storeToRefs } from 'pinia';
  const mainStore = useMainStore(store)
  const { locale, productId } = storeToRefs(mainStore)
  const infos = ref({
    zh: [
      {
        title: '三维可视化',
        icon1: require('@/assets/imgs/product-1.png'),
        list: [
          {
            title: '三维可视化平台',
            id: 1
          },
          {
            title: '倾斜摄影建模软件',
            id: 2
          },
          {
            title: 'LINK滤波软件',
            id: 3
          }
        ]
      },
      {
        title: '智慧城市',
        icon1: require('@/assets/imgs/product-2.png'),
        list: [
          {
            title: '智慧水务',
            id: 4
          },
          {
            title: '数字乡村',
            id: 5
          },
          {
            title: '未来社区',
            id: 6
          },
          {
            title: '综合枢纽',
            id: 7
          }
        ]
      },
      {
        title: '测绘业务',
        icon1: require('@/assets/imgs/product-3.png'),
        list: [
          {
            title: '工程测量',
            id: 8
          },
          {
            title: '地下空间测绘',
            id: 9
          },
          {
            title: '界线与不动产测绘',
            id: 10
          },
          {
            title: '测绘航空摄影',
            id: 11
          }
        ]
      }
    ],
    en: [
      {
        title: '3D Visualization',
        icon1: require('@/assets/imgs/product-1.png'),
        list: [
          {
            title: '3D Visualization',
            id: 1
          },
          {
            title: 'Tilt Photography Modeling Software',
            id: 2
          },
          {
            title: 'LINK Filtering Software',
            id: 3
          }
        ]
      },
      {
        title: 'Smart City',
        icon1: require('@/assets/imgs/product-2.png'),
        list: [
          {
            title: 'Smart Water',
            id: 4
          },
          {
            title: 'Digital Village',
            id: 5
          },
          {
            title: 'Future Community',
            id: 6
          },
          {
            title: 'Integrated Hub',
            id: 7
          }
        ]
      },
      {
        title: 'Surveying and mapping business',
        icon1: require('@/assets/imgs/product-3.png'),
        list: [
          {
            title: 'Engineering Surveying',
            id: 8
          },
          {
            title: 'Underground Space Mapping',
            id: 9
          },
          {
            title: 'Boundary and Real Estate Mapping',
            id: 10
          },
          {
            title: 'Mapping Aerial Photography',
            id: 11
          }
        ]
      }
    ]
  })
  const handleClick = ({ id }) => {
    mainStore.setProductId(id)
  } 
</script>

<style lang="scss" scoped>
  .products {
    width: 360px;
    background: linear-gradient(172.73deg, #63718D 0%, #39455C 100%);
    padding: 40px;
    flex-shrink: 0;
    box-sizing: border-box;
    .title1 {
      font-family: 'HarmonyOS Sans';
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      color: #FFFFFF;
    }
    .group-list {
      .product-group {
        position: relative;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        &::before {
          display: block;
          content: '';
          position: absolute;
          left: 0;
          top: -1px;
          width: 32px;
          height: 0;
          box-sizing: border-box;
          border-bottom: 1px solid #fff;
        }
        .group-name {
          .name {
            font-family: 'HarmonyOS Sans';
            font-weight: 500;
            font-size: 20px;
            line-height: 23px;
            color: #FFFFFF;
          }
        }
        .product-list {
          .product-item {
            font-family: 'HarmonyOS Sans';
            font-weight: 400;
            font-size: 16px;
            color: #D6DDEB;
            cursor: pointer;
            transition: 0.56s;
            background-size: 0px auto;
            background-image: url('~@/assets/imgs/product-arrow.png');
            background-position: left center;
            background-repeat: no-repeat;
            padding-left: 0;
            &.active {
              font-weight: 600;
              font-size: 20px;
              // line-height: 23px;
              color: #FFFFFF;
              padding-left: 40px;
              background-size: 32px auto;
            }
          }
        }
        &:last-child {
          padding-bottom: 24px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }
      }
    }
  }

</style>