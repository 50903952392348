<!--
 * @Author: ce
 * @Date: 2022-09-06 15:26:28
 * @LastEditors: ce
 * @LastEditTime: 2022-09-09 11:49:07
 * @FilePath: /hitarget-offical/src/views/product/components/case.vue
-->
<template>
  <div class="case mt-48 flex content-center">
    <div class="case-inner">
      <h2 class="title main-title"  v-if="data && data.length">{{ $t('product.title2') }}</h2>
      <div class="case-content mt-32 mb-48" v-if=" data && data.length">
        <div class="case-tabs">
          <div class="tab-list flex items-center">
            <div class="tab-item" v-for="(item, index) in data" :key="index" @click="handleClick(item)" :class="{ active: active === item.id }">
              <span>{{ item.name }}</span>
            </div>
          </div>
          <template v-for="(item, index) in data" :key="index">
            <div class="tab-content mt-16 pb-48" v-if="item.id === active">
              <div class="case-info flex content-between items-center">
                <div class="info-detail">
                  <span class="title1">{{ item.title }}</span>
                  <ul v-if="item.tips" class="tips flex flex-wrap mt-12">
                    <li class="tip-item" v-for="(z, zIndex) in item.tips" :key="zIndex">{{ z }}</li>
                  </ul>
                  <template v-if="Array.isArray(item.text)">
                      <ul class="list-text mt-24">
                        <li v-for="(z, zIndex) in item.text" :key="zIndex">{{ z }}</li>
                      </ul>
                  </template>
                  <p v-else class="text mt-24 pb-40">{{ item.text }}</p>

                  <div class="base-info flex items-start content-between pt-40">
                    <div class="baseinfo-item flex flex-wrap">
                      <span>{{ $t('product.label1') }}</span>
                      <i>{{ item.customer }}</i>
                    </div>
                    <div class="baseinfo-item ml-10 flex flex-wrap">
                      <span>{{ $t('product.label2') }}</span>
                      <i>{{ item.time }}</i>
                    </div>
                    <div class="baseinfo-item ml-10 flex flex-wrap">
                      <span>{{ $t('product.label3') }}</span>
                      <i>{{ item.customer }}</i>
                    </div>
                  </div>
                </div>
                <div class="case-img ml-10">
                  <img class="w-full h-full" :src="item.img" alt="">
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="consultation w-full flex items-center content-between "> 
        <div class="left flex items-center content-between">
          <div class="img h-160 w-160">
            <img class="w-full h-full" :src="require('@/assets/imgs/consultation-1.png')" alt="">
          </div>
          <div class="ml-10">
            <p class="label">{{ $t('product.label4') }}</p>
            <p class="tel mt-10">{{ cooperationInfo.tel }}</p>
          </div>
        </div>
        <div class="right flex flex-vertical items-center">
          <img class="h-100 w-100" :src="cooperationInfo.qrcode" alt="">
          <span class="scan">{{ $t('product.label5') }}</span>
          <!-- <span class="label">{{ $t('product.label6') }}</span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup> 
  // eslint-disable-next-line no-undef
  const props = defineProps(['data', 'cooperationInfo'])
  import { onMounted, ref, watch } from 'vue'
  const active = ref(1)
  const handleClick = ({ id }) => {
    active.value = id
  }
  onMounted(() => {
  })
  watch(() => props.data, () => {
    active.value = 1
  }, {
    deep: true,
    immediate: true
  })
</script>

<style lang="scss" scoped>
  .list-text {
    li {
      font-family: 'Source Han Sans CN';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #626773;
      padding-left: 14px;
      background-image: url('~@/assets/imgs/list-1.png');
      background-size: 4px 4px;
      background-position: left 10px;
      background-repeat: no-repeat;
    }
  }
  .tips {
    .tip-item {
      font-family: 'Source Han Sans CN';
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      color: #4E82EC;
      width: 293px;
      padding: 21px 0px 10px;
      box-sizing: border-box;
      margin-right:6px;
      background-image: url('~@/assets/imgs/tip-1.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center center;
      &:nth-child(2n) {
        margin: 0;
      }
      &:nth-child(n+3) {
        margin-top: 11px;
      }
    }
  }
  .case {
    .case-inner {
      max-width: 1220px;
    }
    .title {
      font-family: 'HarmonyOS Sans';
      font-weight: 700;
      font-size: 33px;
      line-height: 39px;
      color: #202632;
    }
    .case-content {
      .case-tabs {
        .tab-list {
          .tab-item {
            font-family: 'Source Han Sans CN';
            font-size: 20px;
            line-height: 30px;
            color: #626773;
            padding: 0 24px;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            cursor: pointer;
            span {
              transition: .28s;
            }
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              border: none;
            }
            &.active {
              font-weight: 700;
              font-size: 28px;
              color: #0055FF;
              span {
                transform: scale(1.1);
              }
            }
          }
        }
        .tab-content {
          border-bottom: 1px solid rgba(0, 0 ,0 , .1);
          .case-info {
            .info-detail {
              max-width: 592px;
              padding: 24px 0;
              .title1 {
                font-family: 'HarmonyOS Sans';
                font-weight: 500;
                font-size: 24px;
                line-height: 28px;
                color: #000000;
              }
              .text {
                font-family: 'HarmonyOS Sans';
                font-size: 16px;
                line-height: 28px;
                text-align: justify;
                color: #626773;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
              }
              .base-info {
                .baseinfo-item {
                  font-family: 'HarmonyOS Sans';
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 28px;
                  text-align: justify;
                  color: #313743;
                  span {
                    font-family: 'HarmonyOS Sans';
                    font-size: 16px;
                    line-height: 28px;
                    color: #626773;
                    padding-right: 8px;
                  }
                  i {
                    white-space: break-spaces;
                    padding-right: 10px;
                    text-align: left;
                  }
                }
              }
            }
            .case-img {
              height: 326px;
              width: 580px;
            }
          }
        }
      }
    }
  }

.consultation {
  height: 184px;
  border: 1px solid #DBE3F4;
  border-radius: 8px;
  padding: 12px 16px;
  min-width: 1000px;
  .left {
    .label {
      font-family: 'HarmonyOS Sans';
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: 0.05em;
      color: #0055FF;
    }
    .tel {
      font-family: 'HarmonyOS Sans';
      font-style: italic;
      font-weight: 900;
      font-size: 44px;
      line-height: 53px;
      letter-spacing: 0.1em;
      color: #313743;
    }
  }
  .right {
    .scan {
      font-family: 'HarmonyOS Sans';
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.05em;
      color: #202632;
    }
    .label {
      font-family: 'HarmonyOS Sans';
      font-size: 12px;
      line-height: 28px;
      color: #626773;
    }
  }
}
</style>