<!--
 * @Author: ce
 * @Date: 2022-09-06 15:26:28
 * @LastEditors: ce
 * @LastEditTime: 2022-09-09 14:20:33
 * @FilePath: /hitarget-offical/src/views/product/components/advantage.vue
-->
<template>
  <div class="advantage mt-48">
    <h2 class="title main-title">{{ productId === 8 ? $t('product.title6') : $t('product.title1') }}</h2>
    <div class="advantage-content">
      <h3 class="title1 mt-32" v-if="data.text">{{ data.text }}</h3>
      <div class="advantage-list flex flex-wrap items-stretch">
        <template v-if="data.type === '1'">
          <div 
            class="advantage-item flex items-start content-start flex-vertical type1" 
            v-for="(item, index) in data.list" 
            :key="index"
            :class="{
              width1: data.list.length === 3,
              width2: data.list.length === 4,
              width3: data.list.length === 5,
              width4: data.list.length === 8
            }"
          >
            <div class="title flex items-center">
              <img class="h-44 w-44" :src="item.icon" alt="">
              <span class="title2 ml-13">{{ item.title }}</span>
            </div>
            <ul class="text mt-12" v-if="item.text">
              <li class="mt-6" v-for="(z, zIndex) in item.text" :key="zIndex">{{ z }}</li>
            </ul>
          </div>
        </template>
        <template v-else>
          <div 
            class="advantage-item flex items-center content-start flex-vertical" 
            v-for="(item, index) in data.list" 
            :key="index" 
            :class="{
              width1: data.list.length === 3,
              width2: data.list.length === 4,
              width3: data.list.length === 5,
              width4: data.list.length >= 8
            }"
          >
            <img class="h-44 w-44" :src="item.icon" alt="">
            <span class="title2 mt-12">{{ item.title }}</span>
            <p class="text mt-8" v-if="item.text">{{ item.text }}</p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup> 
  // eslint-disable-next-line no-undef
  defineProps(['data'])
   import { useMainStore } from "@/store/modules/main.js";
  import store from '@/store/index'
  import { storeToRefs } from 'pinia';
  const mainStore = useMainStore(store)
  const { productId } = storeToRefs(mainStore)
</script>

<style lang="scss" scoped>
  .width1 {
    width: 390px;
    margin-right: 24px;
  }
  .width2 {
    width: 290px;
    margin-right: 20px;
  }
  .width3 {
    width: 228px;
    margin-right: 20px;
  }
  .width4 {
    width: 290px;
    margin-right: 20px;
    &:nth-child(4n) {
      margin-right: 0;
    }
  }
  .advantage {
    .title {
        font-family: 'HarmonyOS Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 33px;
        line-height: 39px;
        color: #202632;
    }
    .advantage-content {
      .title1 {
        font-family: 'HarmonyOS Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 38px;
        text-align: center;
        color: #202632;
      }
      .advantage-list {
        .advantage-item {
          padding: 24px;
          box-sizing: border-box;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          margin-top: 32px;
          .title2 {
            font-family: 'Source Han Sans CN';
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            text-align: center;
            color: #202632;
            white-space: break-spaces;
          }
          &:last-child {
            margin-right: 0;
          }
          .text {
            font-family: 'Source Han Sans CN';
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: #626773;
          }
          &.type1 {
            .text {
              li {
                text-align: left;
                padding-left: 14px;
                background-image: url('~@/assets/imgs/list-1.png');
                background-size: 4px 4px;
                background-position: left 9px;
                background-repeat: no-repeat;
                &:first-child {
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
    }
  }
</style>