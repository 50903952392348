<!--
 * @Author: ce
 * @Date: 2022-09-01 13:22:42
 * @LastEditors: ce
 * @LastEditTime: 2022-09-09 14:36:57
 * @FilePath: /hitarget-offical/src/views/product/index.vue
-->
<template>
  <div class="flex items-stretch">
    <ProductList/>
    <div class="product-detail flex content-center  mb-130" ref="domRef">
      <div class="detail-inner pt-80 ">
        <Introduction :data="infos[locale].introduction"/>
        <Advantage :data="infos[locale].advantages" v-if="productId !== 9 && productId !== 11"/>
        <Business v-if="productId === 9" />
        <Project v-if="productId === 9"/>
        <Project2 v-if="productId === 10"/>
        <Applications v-if="productId === 11"></Applications>
        <Case2 
          v-if="productId === 2" 
          :data="infos[locale].cases2"
          :cooperationInfo="infos[locale].cooperationInfo" 
        />
        <Case3 
          v-else-if="productId === 3" 
          :data="infos[locale].cases3"
          :cooperationInfo="infos[locale].cooperationInfo" 
        />
        <Case 
          v-else 
          :data="infos[locale].cases" 
          :cooperationInfo="infos[locale].cooperationInfo"
        />
      </div>
    </div>
  </div>
</template>

<script  setup>
  import ProductList from './components/productList.vue'
  import Introduction from './components/introduction.vue'
  import Advantage from './components/advantage.vue'
  import Case from './components/case.vue'
  import Case2 from './components/case2.vue'
  import Case3 from './components/case3.vue'
  import Business from './components/business.vue'
  import Applications from './components/applications.vue'
  import Project from './components/project.vue'
  import Project2 from './components/project2.vue'
  import { product_data } from './js/index'
  import { 
    computed, 
    nextTick, 
    onBeforeUnmount, 
    onMounted, 
    ref, 
    watch 
  } from 'vue'
  import { useMainStore } from "@/store/modules/main.js";
  import store from '@/store/index'
  import { storeToRefs } from 'pinia';
  import { useRoute } from 'vue-router'
  const mainStore = useMainStore(store)
  const { locale, productId } = storeToRefs(mainStore)
  // const productId = ref(10)
  const data = ref(product_data)
  const infos = computed(() => data.value.filter(item => item.id === productId.value)[0])
  const route = useRoute()
  const domRef = ref(null)
  watch(route, () => {
    const { query: { id } } = route
    if(id) {
      mainStore.setProductId(Number(id))
    } else {
      mainStore.setProductId(Number(1))
    }
  },{
    immediate: true
  })
  watch(productId, () => {
      const viewPort = document.getElementById('viewPort')
      viewPort.scrollTo({
        left: 0,
        top: 0,
        behavior: 'instant'
      })
      domRef.value.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth'
      })
  })
  const viewPortScroll = () => {
    const viewPort = document.getElementById('viewPort')
    if(viewPort.scrollTop === 0) {
      domRef.value.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth'
      })
    }
  }
  onMounted(() => {
    nextTick(() => {
      const viewPort = document.getElementById('viewPort')
      viewPort.addEventListener('scroll', viewPortScroll)
    })
  })
  onBeforeUnmount(() => {
    const viewPort = document.getElementById('viewPort')
    viewPort.removeEventListener('scroll', viewPortScroll)
  })
</script>

<style lang="scss" scoped>
  .product-detail {
    flex: 1;
    height: calc(100vh - 72px);
    overflow: overlay;
    .detail-inner {
      max-width: 1220px;
    }
  }
</style>